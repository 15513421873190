import { useAppSelector } from '@hooks/useAppSelector';
import { NeighborCampaigns } from '@services/hafh/types/generated';

export const useIsNeighborCampaignActiveAndEligible = (
  campaignName: keyof NeighborCampaigns
) => {
  const { campaigns } = useAppSelector((state) => state.campaign);

  const campaign = campaigns?.[campaignName];

  return !!(campaign?.status?.active && campaign?.status?.eligible);
};

export const GLOBAL_REFERRAL_CAMPAIGN_CODE = '20241009-glb-basic';

export const SIGN_UP_CAMPAIGN_START_DATE = '2024-12-19T00:00:00+0900';

export const SIGN_UP_CAMPAIGN_END_DATE = '2025-01-05T23:59:59+0900';

export const GACHA_20250306_CAMPAIGN_START_DATE = '2025-03-06T00:00:00+0900';

export const GACHA_20250306_CAMPAIGN_END_DATE = '2025-03-31T23:59:59+0900';

export const SIGN_UP_CAMPAIGN_TOPIC_URL = 'https://www.hafh.com/topics/21758';

export const SIGN_UP_CAMPAIGN_CODE = '20241219_jp_30%off';

export const POTENTIAL_USER_CAMPAIGN_START_DATE = '2025-01-22T00:00:00+0900';

export const POTENTIAL_USER_CAMPAIGN_END_DATE = '2025-01-31T23:59:59+0900';

export const getIsDuringSignUpCampaign = () => {
  const now = Date.now();

  return (
    now >= Date.parse(SIGN_UP_CAMPAIGN_START_DATE) &&
    now <= Date.parse(SIGN_UP_CAMPAIGN_END_DATE)
  );
};

export const getIsDuringGacha20250306Campaign = () => {
  const now = Date.now();

  return (
    now >= Date.parse(GACHA_20250306_CAMPAIGN_START_DATE) &&
    now <= Date.parse(GACHA_20250306_CAMPAIGN_END_DATE)
  );
};
